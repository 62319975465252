<template>
  <div>
    <el-table :data="table.data" :loading="table.loading" style="width: 100%">
      <el-table-column :label="$tc('event')" prop="logEmailEvent" width="100" align="center">
        <template slot-scope="scope">
          <el-tag :type="getClassEvent(scope.row)">
            <span>{{ $tc(scope.row.logEmailEvent) }}</span>
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        :label="$tc('recipient')"
        prop="logEmailRecipient"
        width="180"
      ></el-table-column>
      <el-table-column :label="$tc('event')" prop="logEventTimestamp" width="180">
        <template slot-scope="scope">
          <span>{{ scope.row.logEventTimestamp | formatToDateTime }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$tc('level')" prop="logEmailLevel" width="100">
        <template slot-scope="scope">
          <span>{{ $tc(scope.row.logEmailLevel) }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$tc('description')" prop="logEmailDescription" width="auto">
        <template slot-scope="scope">
          <span>{{ scope.row.logEmailDescription }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import list, { table } from '@/views/common/list'

export default {
  extends: list('messenger'),
  props: {
    entityType: { type: String, required: false, default: 'Voucher' },
    entityId: { type: String, required: true }
  },
  data() {
    return {
      table: {
        ...table()
      }
    }
  },
  created() {
    this.get(`hub/findByServiceByTypeByEntity/${this.entityType}/${this.entityId}`)
  },
  methods: {
    getClassEvent(entity) {
      let entityClass = 'success'
      switch (entity.logEmailEvent) {
        case 'opened':
          entityClass = 'primary'
          break
        case 'acepted':
          entityClass = 'info'
          break
        case 'failed':
          entityClass = 'danger'
          break
        default:
          entityClass = 'success'
          break
      }

      return entityClass
    }
  }
}
</script>
